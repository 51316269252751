import axios from "axios";
import {
  constructNestedComponentsForComponent,
  decorateComponents,
  deriveValueFromProp,
  fixComponentChildrenIds,
  getComponentNestedChildrenIds,
  syncComponents
} from "@/store/builder-pro/utils";
import {
  BuilderProActions,
  EHistoryStepActionType,
  TComponent,
  TFont, THistoryStepActionRemoveDynamicComponent
} from "@/store/builder-pro/types";
import {
  ALL_ELEMENTS,
  DEFAULT_FONTS,
  DYNAMIC_DATA_COMPONENT_KEYS_OBJ,
  DYNAMIC_DATA_COMPONENTS,
  EComponents,
  EComponentTypes,
  EDITOR_SIZES_LIST,
  EEditorSizes
} from "@/store/builder-pro/consts";
import _cloneDeep from "lodash/cloneDeep";

const BASE_URL = process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL;

const componentActions: Partial<BuilderProActions> = {
  addToUpdatedComponents(updatedComponent) {
    if (!this.updatedComponents.includes(updatedComponent))
      this.updatedComponents.push(updatedComponent)
  },

  addToLoadedComponents({ lang, template, page, components }) {
    const decoratedComponents = decorateComponents(lang, template, page);
    if (!this.loadedComponents.find((c) => c === decoratedComponents)) {
      this.loadedComponents.push(decoratedComponents);
    }
    if (components?.length) {
      if (this.epackData.components instanceof Array) this.epackData.components = {};
      this.epackData.components[lang] = {
        ...(this.epackData.components[lang] || {}),
        [template]: {
          ...(this.epackData.components[lang]?.[template] || {}),
          [page]: components,
        },
      };
      this.resetHistoryMulti({ locale: lang, template, page });
    }
  },

  async loadComponents({ force, epackId, lang, template, page }) {
    if (
      force ||
      (epackId &&
        !this.loadedComponents.includes(decorateComponents(lang, template, page)) &&
        !!this.epackData.components[lang]?.[template]?.[page])
    ) {
      await axios
        .get(`${BASE_URL}/api/constructor/epackages/${epackId}/components?lang=${lang}&template=${template}&page=${page}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
        .then((res) => {
          this.addToLoadedComponents({
            lang,
            template,
            page,
            components: syncComponents(res.data?.data?.components || []), // .slice(0, 100)
          });
          this.updateTemplateConfiguration({
            lang,
            template,
            templateConfiguration: res.data?.data?.templateConfiguration || null,
          });
        })
        .catch((e) => {
          if (e.response?.status === 404) {
            this.addToLoadedComponents({
              lang,
              template,
              page,
              components: [],
            });
          } else {
            console.error(e);
          }
        });
    } else if (!this.epackData.components[lang]?.[template]?.[page]) {
      this.addToLoadedComponents({
        lang,
        template,
        page,
        components: [],
      });
    }
  },

  setActiveComponent({ component: _component, dynamicOrder }) {
    this.activeComponent = _component ?? null;
    this.activeComponentDynamicOrder = dynamicOrder ?? null;
  },

  updateUsedFonts() {
    const components = this.epackData.components[this.activeLocale][this.activeTemplate][this.activePage]
    // go through all components and get all usedFonts
    const usedFonts: TFont[] = []
    components.forEach(c => {
      // value can be undefined | string | object with sizes
      if (c.props && c.props.fontFamily && c.props.fontStyle && c.props.fontWeight) {
        EDITOR_SIZES_LIST.forEach(({key: size}) => {
          const fontFamily = deriveValueFromProp(c.props!.fontFamily!.value, size)
          const fontStyle = deriveValueFromProp(c.props!.fontStyle!.value, size)
          const fontWeight = deriveValueFromProp(c.props!.fontWeight!.value, size)
          if (fontFamily && fontWeight && fontStyle
            && !usedFonts.find(f => f.fontFamily === fontFamily && f.fontStyle === fontStyle && f.fontWeight === fontWeight)
            && !DEFAULT_FONTS.find(f => f.fontFamily === fontFamily)) {
            usedFonts.push({
              fontFamily,
              fontStyle,
              fontWeight
            })
          }
        })
      }
    })
    this.epackData.usedFonts = usedFonts
    this.onEpackDataUpdate()
  },

  handleComponentAfterAddingToComponents(addedComponent: TComponent) {
    if ([EComponents.TAB_BUTTON, EComponents.TAB_BUTTON_WITH_STATE].includes(addedComponent.componentKey)) {
      // setting tabGroup to the last selected one
      if (addedComponent.props?.tabGroup && !addedComponent.props.tabGroup.value) {
        addedComponent.props.tabGroup.value = { [EEditorSizes.DESKTOP]: this.localStates.lastSelectedTabGroup }
      }

      // sync allowToggle with first found TAB_BUTTON of the same group
      if (addedComponent.props?.allowToggle && !addedComponent.props.allowToggle.value) {
        const components = this.epackData.components[this.activeLocale][this.activeTemplate][this.activePage]
        for (let i = 0; i < components.length; i += 1) {
          if (
            components[i].id !== addedComponent.id &&
            [EComponents.TAB_BUTTON, EComponents.TAB_BUTTON_WITH_STATE].includes(components[i].componentKey) &&
            components[i].props?.tabGroup?.value?.[EEditorSizes.DESKTOP]
            === addedComponent.props?.tabGroup?.value?.[EEditorSizes.DESKTOP] &&
            components[i].props?.allowToggle?.value?.[EEditorSizes.DESKTOP]
          ) {
            addedComponent.props.allowToggle.value =
              { [EEditorSizes.DESKTOP]: components[i].props!.allowToggle!.value[EEditorSizes.DESKTOP] }
            break;
          }
        }
      }
    } else if (addedComponent.componentKey === EComponents.TAB_CONTENT) {
      // setting a uniqueId to the newly added TAB_CONTENT
      let newTabContentUniqueId = 0;
      const components = this.epackData.components[this.activeLocale][this.activeTemplate][this.activePage]
      for (let i = 0; i < components.length; i += 1) {
        if (
          components[i].componentKey === EComponents.TAB_CONTENT &&
          !!components[i].props?.uniqueId?.value?.[EEditorSizes.DESKTOP] &&
          components[i].props!.uniqueId!.value[EEditorSizes.DESKTOP] > newTabContentUniqueId
        ) {
          newTabContentUniqueId = components[i].props!.uniqueId!.value[EEditorSizes.DESKTOP]
        }
      }
      newTabContentUniqueId += 1;
      addedComponent.props!.uniqueId!.value = {
        [EEditorSizes.DESKTOP]: newTabContentUniqueId
      }
      addedComponent.props!.openByDefault = {
        ...addedComponent.props!.openByDefault,
        value: {
          [EEditorSizes.DESKTOP]: 'off'
        }
      }
    }

    if (addedComponent.children) {
      addedComponent.children.forEach((child) => {
        this.handleComponentAfterAddingToComponents(child);
      })
    }
  },

  removeComponent(_component: TComponent, resetActiveComponent = true) {
    const components = this.epackData.components[this.activeLocale][this.activeTemplate][this.activePage]

    const idsToRemove = [_component.isDynamic && !!_component.dynamicIndex ? components[_component.dynamicIndex].id! : _component.id!]
    if (_component.isDynamic && !!_component.dynamicIndex) {
      getComponentNestedChildrenIds(components[_component.dynamicIndex].id, components, idsToRemove)
    } else {
      getComponentNestedChildrenIds(_component.id, components, idsToRemove)
    }

    const removedComponents: TComponent[] = [];

    idsToRemove.forEach(id => {
      removedComponents.push(...components.splice(components.findIndex(c => c.id === id), 1));
    })

    this.addToHistory({
      type: EHistoryStepActionType.REMOVE_COMPONENT,
      components: removedComponents,
    })

    if (resetActiveComponent) {
      this.setActiveComponent({
        component: null
      })
    }
    this.onEpackDataUpdate()
  },

  reorderComponent({ draggedComponent, desiredParentComponent, newIndex }) {
    const components = this.epackData.components[this.activeLocale][this.activeTemplate][this.activePage]
    const desiredParentComponentId = desiredParentComponent.id || 0
    let draggedComponentInEpack: TComponent | undefined;
    const draggedComponentParent = draggedComponent.dynamicIndex !== undefined ? components[draggedComponent.dynamicIndex] : undefined
    if (draggedComponentParent && DYNAMIC_DATA_COMPONENT_KEYS_OBJ[draggedComponentParent.name]) {
      draggedComponentInEpack = draggedComponentParent
    }
    const desiredParentChildren = components.filter((_component) => {
      if (_component.id === draggedComponent.id) {
        if (!draggedComponentInEpack) draggedComponentInEpack = _component
        return false
      }
      if (draggedComponentInEpack && _component.id === draggedComponentInEpack.id) return false
      return (_component.parentId === (desiredParentComponentId || null)) || (_component.parentId === 0 && desiredParentComponentId === 0)
    })
    if (draggedComponentInEpack) {
      const originalParentId = draggedComponentInEpack.parentId || null;
      const originalOrder = draggedComponentInEpack.order!;
      const originalRawDesiredParentChildren = desiredParentChildren.map<TComponent>(component => ({
        componentKey: component.componentKey,
        name: component.name,
        type: component.type,
        id: component.id,
        order: component.order,
        parentId: component.parentId,
      }))

      draggedComponentInEpack.parentId = desiredParentComponentId
      // sort the children to add the dragged component at the correct index
      desiredParentChildren.sort((a, b) => !!a.order && !!b.order && b.order < a.order ? 1 : -1)
      // adding the dragged component at the correct index to fix the orders after
      //  (note that desiredParentChildren is a new instance, so it doesn't change components)
      desiredParentChildren.splice(newIndex, 0, draggedComponentInEpack)
      // fix the orders of the children inside the component
      //  taking into account that there's a new child
      desiredParentChildren.forEach((child, index) => {
        child.order = index + 1
      })

      this.addToHistory({
        type: EHistoryStepActionType.REORDER_COMPONENT,
        componentId: draggedComponentInEpack.id!,
        prevParentId: originalParentId || 0,
        newParentId: desiredParentComponentId,
        prevOrder: originalOrder,
        newOrder: newIndex + 1,
        desiredRawParentChildrenWithoutDraggedComponent: originalRawDesiredParentChildren,
      })

      this.onEpackDataUpdate()
    }
  },

  updateComponentProp({ prop, updatedProp, component: providedComponent }) {
    const _component = providedComponent || this.activeComponent;
    if (_component) {
      const components = this.epackData.components[this.activeLocale][this.activeTemplate][this.activePage]
      const componentInEpack = components.find(c => c.id === _component.id)!

      let prevUsedFonts: TFont[] | undefined;
      let newUsedFonts: TFont[] | undefined;
      const prevProp = _cloneDeep(componentInEpack.props![prop.key]!);

      _component.props![prop.key] = updatedProp
      componentInEpack.props![prop.key] = updatedProp


      if (['fontFamily', 'fontStyle', 'fontWeight'].includes(prop.key)) {
        prevUsedFonts = _cloneDeep(this.epackData.usedFonts);

        this.updateUsedFonts()

        newUsedFonts = _cloneDeep(this.epackData.usedFonts);
      }

      this.addToHistory({
        type: EHistoryStepActionType.UPDATE_COMPONENT_PROP,
        componentId: componentInEpack.id!,
        propKey: prop.key,
        prevProp,
        newProp: componentInEpack.props![prop.key]!,
        prevUsedFonts,
        newUsedFonts,
      })

      this.onEpackDataUpdate()
    }
  },

  addRawComponent({
    component,
    order,
    parentComponentId,
    resetChildrenOrders = true,
    beforeAddToComponents
  }) {
    const components = this.epackData.components[this.activeLocale][this.activeTemplate][this.activePage]
    let componentToAdd: TComponent;
    try {
      componentToAdd = JSON.parse(JSON.stringify(component)) as TComponent;
    } catch {
      componentToAdd = _cloneDeep(component)
    }
    componentToAdd.parentId = parentComponentId
    componentToAdd.id = components.reduce((prev, cur) => prev < cur.id! ? cur.id! : prev, 0) + 1
    componentToAdd.order = order || components
      .filter(c => c.parentId === parentComponentId)
      .reduce((prev, cur) => prev < cur.order! ? cur.order! : prev, 0) + 1
    beforeAddToComponents?.(componentToAdd);
    components.push(componentToAdd)
    const children = fixComponentChildrenIds(componentToAdd, components, resetChildrenOrders)

    this.addToHistory({
      type: EHistoryStepActionType.ADD_RAW_COMPONENT,
      components: [componentToAdd, ...children],
    })

    this.onEpackDataUpdate()
    return componentToAdd;
  },

  addComponent({
    customName,
    componentKey,
    isCustom = false,
    insideActive = false,
    makeActive = true,
    additionalProps,
    addOutside = false
  }) {
    let increaseOrdersAfterComponentToAdd = false;

    const components = this.epackData.components[this.activeLocale][this.activeTemplate][this.activePage]

    const componentIdsWithIncreasedOrdersForHistory: number[] = [];

    let componentToAdd = (isCustom
      ? this.epackData.customComponents?.find(c => c.customName === customName)
      : ALL_ELEMENTS[componentKey as keyof typeof ALL_ELEMENTS]) as TComponent
    if (!componentToAdd) {
      console.error(`ERROR in addComponent: name doesn't match any existing components (${customName || componentKey})`)
      return
    }
    try {
      componentToAdd = JSON.parse(JSON.stringify(componentToAdd)) as TComponent;
    } catch {
      componentToAdd = _cloneDeep(componentToAdd)
    }
    componentToAdd.id = components.reduce((prev, cur) => prev < cur.id! ? cur.id! : prev, 0) + 1
    if (additionalProps) {
      componentToAdd.props = {
        ...componentToAdd.props,
        ...additionalProps
      }
    }
    if (!addOutside && this.activeComponent) {
      const isComponentFeature = componentToAdd.type === EComponentTypes.FEATURE;

      if (!isComponentFeature && insideActive) {
        componentToAdd.parentId = this.activeComponent.id
        componentToAdd.order = components.filter(c => c.parentId === this.activeComponent!.id)
          .reduce((prev, cur) => prev < cur.order! ? cur.order! : prev, 0) + 1
      } else {
        // it's possible to meet a case when the parent of the activeComponent is a Dynamic Data component
        // in that case we need to get the parent and the order of that Dynamic Data component instead of the activeComponent
        let activeComponentParent = components.find(c => c.id === this.activeComponent!.parentId)
        let componentToAddAfter = this.activeComponent
        if (activeComponentParent && DYNAMIC_DATA_COMPONENT_KEYS_OBJ[activeComponentParent.name]) {
          componentToAddAfter = activeComponentParent
        }
        if ((!isComponentFeature || (isComponentFeature && !this.activeComponent.parentId)) && componentToAddAfter) {
          componentToAdd.parentId = componentToAddAfter.parentId
          componentToAdd.order = componentToAddAfter.order! + 1
          increaseOrdersAfterComponentToAdd = true;
        } else {
          // same as if the activeComponent didn't exist
          while (activeComponentParent?.parentId !== 0) {
            activeComponentParent = components.find(c => c.id === activeComponentParent?.parentId);
          }
          componentToAdd.parentId = activeComponentParent?.parentId;
          componentToAdd.order = activeComponentParent?.order! + 1
          increaseOrdersAfterComponentToAdd = true;
        }
      }
    } else {
      componentToAdd.parentId = 0
      componentToAdd.order = components.filter(c => !c.parentId)
        .reduce((prev, cur) => prev < cur.order! ? cur.order! : prev, 0) + 1
    }

    // for now, handle an amazon-specific case here
    if (
      this.activeTemplate === 'amazon_template'
      && !componentToAdd.parentId
      && components.filter(comp => !comp.parentId).length >= 5
    ) {
      return 'Amazon template allows only 5 components per page'
    }

    if (increaseOrdersAfterComponentToAdd) {
      // increase the order of components coming after
      components.filter(_component => (
        (componentToAdd.parentId === 0 && _component.parentId === 0) || (_component.parentId === (componentToAdd.parentId || null))
      ) && _component.order! >= componentToAdd.order!).forEach(_component => {
        componentIdsWithIncreasedOrdersForHistory.push(_component.id!);
        _component.order!++
      })
    }

    components.push(componentToAdd)

    const addedComponent = components[components.length - 1];
    this.handleComponentAfterAddingToComponents(addedComponent);

    const dynamicComponentToAddChild =
      DYNAMIC_DATA_COMPONENT_KEYS_OBJ[componentToAdd.name] ? componentToAdd?.children?.[0] : undefined

    const children = fixComponentChildrenIds(componentToAdd, components, true)

    if (makeActive) {
      this.activeComponent = dynamicComponentToAddChild || componentToAdd
    }

    this.addToHistory({
      type: EHistoryStepActionType.ADD_COMPONENT,
      components: [addedComponent, ...children],
      componentIdsWithIncreasedOrders: componentIdsWithIncreasedOrdersForHistory,
    })

    this.onEpackDataUpdate()

    return undefined;
  },

  makeComponentDynamic({ componentId, dynamicName }) {
    const components = this.epackData.components[this.activeLocale][this.activeTemplate][this.activePage]
    let componentToAdd = DYNAMIC_DATA_COMPONENTS[dynamicName as keyof typeof DYNAMIC_DATA_COMPONENTS]
    if (!componentToAdd) {
      console.error(`ERROR in addComponent: dynamicName doesn't match any existing components (${dynamicName})`)
      return
    }
    componentToAdd = _cloneDeep(componentToAdd)
    const componentInEpack = components.find(c => c.id === componentId)!
    componentToAdd.name = dynamicName as EComponents
    componentToAdd.id = components.reduce((prev, cur) => prev < cur.id! ? cur.id! : prev, 0) + 1
    componentToAdd.parentId = componentInEpack.parentId
    componentToAdd.order = componentInEpack.order
    componentInEpack.parentId = componentToAdd.id
    componentInEpack.order = 1
    components.push(componentToAdd)
    this.activeComponent = componentInEpack

    this.addToHistory({
      type: EHistoryStepActionType.MAKE_COMPONENT_DYNAMIC,
      dynamicDataComponent: _cloneDeep(componentToAdd),
      componentId: componentInEpack.id!,
    })

    this.onEpackDataUpdate()
  },

  removeDynamicDataComponent(dynamicId) {
    const components = this.epackData.components[this.activeLocale][this.activeTemplate][this.activePage]
    let dynamicComponent: TComponent
    let dynamicComponentChild: TComponent

    components.forEach(_component => {
      if (_component.id === dynamicId) dynamicComponent = _component
      else if (_component.parentId === dynamicId) dynamicComponentChild = _component
    })
    const idsToRemove = [dynamicId]
    getComponentNestedChildrenIds(dynamicId, components, idsToRemove, [EComponents.DYNAMIC_DATA_RECEIVER])

    const componentPairs: THistoryStepActionRemoveDynamicComponent['componentPairs'] = [];

    idsToRemove.forEach(id => {
      const nestedChildIndex = components.findIndex(c => c.id === id)
      const nestedChildsChild = components.find(c => c.parentId === components[nestedChildIndex].id)!
      nestedChildsChild.parentId = components[nestedChildIndex].parentId
      nestedChildsChild.order = components[nestedChildIndex].order

      componentPairs.push({
        dynamicComponent: components.splice(nestedChildIndex, 1)[0],
        componentId: nestedChildsChild.id!,
      })
    })

    dynamicComponentChild!.parentId = dynamicComponent!.parentId
    dynamicComponentChild!.order = dynamicComponent!.order
    this.activeComponent = dynamicComponentChild!

    this.addToHistory({
      type: EHistoryStepActionType.REMOVE_DYNAMIC_DATA_COMPONENT,
      componentPairs
    })

    this.onEpackDataUpdate()
  },

  saveCustomComponent (rawComponent) {
    if (this.epackData.customComponents.find(cc => cc.customName === rawComponent.customName)) {
      throw 'already-exists'
    }
    const components = this.epackData.components[this.activeLocale][this.activeTemplate][this.activePage]
    const rawComponentParent = rawComponent.parentId ? components.find(c => c.id === rawComponent.parentId) : undefined
    let _component: TComponent;
    if (rawComponentParent && DYNAMIC_DATA_COMPONENT_KEYS_OBJ[rawComponentParent.name]) {
      _component = _cloneDeep(rawComponentParent)
      _component.customName = rawComponent.customName
    }
    else _component = _cloneDeep(rawComponent)
    _component = constructNestedComponentsForComponent(_component, _cloneDeep(components))
    if (DYNAMIC_DATA_COMPONENT_KEYS_OBJ[_component.name] && _component.children?.length) {
      _component.children[0].customName = rawComponent.customName
    }
    this.epackData.customComponents.push(_component)
    this.addToHistory({
      type: EHistoryStepActionType.SAVE_CUSTOM_COMPONENT,
      component: _cloneDeep(_component),
      orderIndex: this.epackData.customComponents.length - 1,
    })
    this.onEpackDataUpdate()
  },

  removeCustomComponent(customName?: string) {
    if (customName) {
      const componentIndex = this.epackData?.customComponents?.findIndex(c => c.customName === customName)
      if (componentIndex >= 0) {
        const [removedCustomComponent] = this.epackData.customComponents.splice(componentIndex, 1)

        this.addToHistory({
          type: EHistoryStepActionType.REMOVE_CUSTOM_COMPONENT,
          component: removedCustomComponent,
          orderIndex: componentIndex,
        });

        this.onEpackDataUpdate();
      }
    }
  },

  syncComponentsLocal (payload) {
    this.epackData.components = _cloneDeep(payload.components)
    this.onEpackDataUpdate()
  },
  syncPageComponentsLocal (payload) {
    this.epackData.components[this.activeLocale][this.activeTemplate][this.activePage] = _cloneDeep(payload.components)
    this.resetHistory();
    this.onEpackDataUpdate()
  }
};

export { componentActions as component };
