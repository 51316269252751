import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS, POSITIONING_PROPS } from '../props';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";

export const ROW: TComponent = {
  componentKey: EComponents.ROW,
  name: EComponents.ROW,
  type: EComponentTypes.COMPONENT,
  withChildren: true,
  props: {
    contextDirection: {
      name: 'Direction',
      defaultValue: 'horizontal',
      items: ['horizontal', 'vertical'],
    },
    size: {
      name: 'Space between children',
      defaultValue: '',
    },
    align: {
      name: 'Align',
      defaultValue: 'start',
      items: ['start', 'center', 'end'],
    },
    flexWrap: {
      name: 'Flex wrap',
      defaultValue: 'nowrap',
      items: ['nowrap', 'wrap', 'wrap-reverse', 'initial'],
    },
    ...POSITIONING_PROPS,
    ...COMMON_PROPS,
  },
};
